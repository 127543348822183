<template>
  <div>
    <h1>404</h1>
    <router-link to="/">
      <button class="btn btn-success">Bosh sahifa</button>
    </router-link>
  </div>
</template>

<script>
export default {};
</script>

<style scoped></style>

import { createRouter, createWebHashHistory } from 'vue-router';
import DefaultPage from '../layouts/default.vue';
import Login from '../layouts/login.vue';
import NotFound from '../layouts/404.vue';

const routes = [
  {
    path: '/',
    component: DefaultPage,
    children: [
      {
        path: '',
        name: 'home',
        component: function () {
          return import('../pages/Index.vue');
        },
        meta: { auth: true },
      },
      {
        path: '/aboutadd',
        name: 'aboutadd',
        component: function () {
          return import('../pages/About/AboutAdd.vue');
        },
        meta: { auth: true },
      },
      {
        path: '/newsadd',
        name: 'newsadd',
        component: function () {
          return import('../pages/News/NewsAdd.vue');
        },
        meta: { auth: true },
      },
      {
        path: '/newssee',
        name: 'newssee',
        component: function () {
          return import('../pages/News/NewsSee.vue');
        },
        meta: { auth: true },
      },
      {
        path: '/newsupdate/:id',
        name: 'newsupdate',
        component: function () {
          return import('../pages/News/NewsUpdate.vue');
        },
        meta: { auth: true },
      },
      {
        path: '/documentadd',
        name: 'documentadd',
        component: function () {
          return import('../pages/Document/DocumentAdd.vue');
        },
        meta: { auth: true },
      },
      {
        path: '/documentsee',
        name: 'documentsee',
        component: function () {
          return import('../pages/Document/DocumentSee.vue');
        },
        meta: { auth: true },
      },
      {
        path: '/documentupdate/:id',
        name: 'documentupdate',
        component: function () {
          return import('../pages/Document/DocumentUpdate.vue');
        },
        meta: { auth: true },
      },
      {
        path: '/teamadd',
        name: 'teamadd',
        component: function () {
          return import('../pages/Team/TeamAdd.vue');
        },
        meta: { auth: true },
      },
      {
        path: '/teamsee',
        name: 'teamsee',
        component: function () {
          return import('../pages/Team/TeamSee.vue');
        },
        meta: { auth: true },
      },
      {
        path: '/teamupdate/:id',
        name: 'teamupdate',
        component: function () {
          return import('../pages/Team/TeamUpdate.vue');
        },
        meta: { auth: true },
      },
      {
        path: '/videoadd',
        name: 'videoadd',
        component: function () {
          return import('../pages/VideoAdd.vue');
        },
        meta: { auth: true },
      },
    ],
  },
  {
    path: '/login',
    name: 'login',
    component: Login,
  },
  {
    path: '/404',
    name: '404',
    component: NotFound,
  },
  {
    path: '/:catchAll(.*)',
    redirect: '/404',
  },
];

const router = createRouter({
  history: createWebHashHistory(),
  routes,
});

router.beforeEach((to, from, next) => {
  const reqAuth = to.meta.auth;
  const token = localStorage.getItem('token');

  if (reqAuth && token) {
    if (to.name == 'login') {
      next({ name: 'home' });
      return;
    }

    next();
    return;
  }

  if (token) {
    if (to.name == 'login') {
      next({ name: 'home' });
      return;
    }
  } else {
    if (to.name != 'login') {
      next({ name: 'login' });
      return;
    }
  }

  next();
});

export default router;

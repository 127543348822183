<template>
  <div>
    <router-view />
  </div>
</template>

<style>
body {
  overflow-x: hidden;
}
</style>

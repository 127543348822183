import { createStore } from 'vuex';

export default createStore({
  state: {
    data_add: false,
    data_error: false,
    data_delete: false,
    data_send_basket: false,
  },
  getters: {},
  mutations: {
    DATA_ADD(state, payload) {
      state.data_add = payload;
    },
    DATA_ERROR(state, payload) {
      state.data_error = payload;
    },
    DATA_DELETE(state, payload) {
      state.data_delete = payload;
    },
    DATA_SEND_BASKET(state, payload) {
      state.data_send_basket = payload;
    },
  },
  actions: {},
  modules: {},
});

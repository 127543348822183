<template>
  <div class="wrapper">
    <div class="logo">
      <h2>Termiz sis</h2>
    </div>
    <div class="accordionn">
      <div
        v-for="(item, index) of sections"
        :key="index"
        class="accordionn"
      >
        <div>
          <div>
            <div class="accordion_button_wrap">
              <div
                class="accordion-btn"
                @click="toggleAccordion(index)"
                :aria-expanded="
                  activeAccordion === index ? 'true' : 'false'
                "
                :aria-controls="`collapse${index}`"
              >
                {{ item.title }}
              </div>
              <div class="controll-btns mr-3"></div>
              <i
                class="fa-solid fa-chevron-down"
                @click="toggleAccordion(index)"
              ></i>
            </div>
          </div>
          <!-- panel -->
          <div
            class="panel"
            v-show="activeAccordion === index"
            :id="`collapse${index}`"
            v-for="i of item.pages"
            :key="i"
          >
            <!-- Accordian content here -->
            <router-link :to="i.path" class="panel-item">
              <span>{{ i.title }}</span>
            </router-link>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      activeAccordion: null,
      sections: [
        {
          title: 'Biz haqimizda',
          pages: [
            {
              title: "Biz haqimizda qo'shish",
              path: '/aboutadd',
            },
          ],
        },
        {
          title: 'Yangilklar',
          pages: [
            {
              title: "Yangilik qo'shish",
              path: '/newsadd',
            },

            {
              title: "Yangiliklarni ko'rish",
              path: '/newssee',
            },
          ],
        },
        {
          title: 'Jamoa',
          pages: [
            {
              title: "Jamoa qo'shish",
              path: '/teamadd',
            },

            {
              title: "Jamoa ko'rish",
              path: '/teamsee',
            },
          ],
        },
        {
          title: 'Video',
          pages: [
            {
              title: "Video qo'shish",
              path: '/videoadd',
            },
          ],
        },
        {
          title: 'Xujjatlar',
          pages: [
            {
              title: "Xujjatlar qo'shish",
              path: '/documentadd',
            },

            {
              title: "Xujjatlar ko'rish",
              path: '/documentsee',
            },
          ],
        },
      ],
    };
  },
  methods: {
    // Accordion
    toggleAccordion(index) {
      if (this.activeAccordion === index) {
        this.activeAccordion = null;
      } else {
        this.activeAccordion = index;
      }
    },
  },
  mounted() {},
};
</script>

<style scoped>
.wrapper {
  width: 250px;
  height: 100vh;
  padding: 15px 10px;
  background-color: #11101d;
  overflow-y: auto;
  position: fixed;
  left: 0;
  top: 0;
  z-index: 100;
  transition: 0.2s;
}
.logo {
  display: flex;
  align-items: center;
}
.logo img {
  width: 50px;
}
.logo h2 {
  margin-top: 5px;
  margin-left: 10px;
  font-family: 'Poppins';
  color: white;
  font-size: 20px;
  font-weight: 600;
}
.accordionn {
  -webkit-user-select: none; /* Safari */
  -ms-user-select: none; /* IE 10 and IE 11 */
  user-select: none; /* Standard syntax */
  margin-top: 10px;
}
.accordion_button_wrap {
  display: flex;
  align-items: center;
  width: 100%;
  padding: 0 15px;
  background-color: #2d2a50;
  color: white;
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
  border-radius: 5px;
}
.accordion-btn {
  display: flex;
  align-items: center;
  width: 100%;
  height: 48px;
}
.fa-chevron-down {
  height: 48px;
  width: 60px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.controll-btns {
  display: flex;
  align-items: center;
  gap: 15px;
}
a,
.controll-btns button {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 30px;
  height: 30px;
  border: none;
  border-radius: 5px;
  color: white;
}

.vostanavit {
  background-color: orange;
}
.panel {
  width: 100%;
  background-color: #11101d;
  cursor: pointer;
}
.panel-item {
  width: 100%;
  height: 48px;
  padding: 0 15px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 15px;
  border: none;
  background-color: #1d1b31;
  color: white;
}
.panel-item:hover {
  background-color: #27253c;
}
/* media  */
@media (max-width: 1360px) {
  .wrapper {
    width: 230px;
  }
}
@media (max-width: 1200px) {
  .wrapper {
    width: 270px;
    left: -100%;
    top: 0;
  }
}
@media (max-width: 500px) {
  .wrapper {
    width: 250px;
  }
}
</style>
